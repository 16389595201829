export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const UPDATE_PRODUCTS_IPFS = 'UPDATE_PRODUCTS_IPFS'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'
export const SET_LOADED_PAGE = 'SET_LOADED_PAGE'
export const SET_SORT_BY = 'SET_SORT_BY'
export const SET_CATEGORY = 'SET_CATEGORY'
export const MY_NFT = 'MY_NFT'
export const NFT_LIKED = 'NFT_LIKED'
export const NFT_ONSALE = 'NFT_ONSALE'
export const NFT_COLLECTIBLES = 'NFT_COLLECTIBLES'
export const NFT_CREATED = 'NFT_CREATED'
export const FOLLOWING = 'FOLLOWING'
export const FOLLOWERS = 'FOLLOWERS'
