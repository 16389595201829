import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import AppRoutes from './AppRoutes'

import { persister, store } from './redux/store/storeConfigs'
import AppThemeProvider from './theme/AppThemeProvider'

function App() {
    return (
        <Provider store={store}>
            <AppThemeProvider>
                <Router>
                    <PersistGate persistor={persister}>
                        <AppRoutes />
                    </PersistGate>
                </Router>
            </AppThemeProvider>
        </Provider>
    )
}

export default App
