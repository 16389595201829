import { ethers } from 'ethers'

export const NFTCategory = {
    Arts: { name: 'Arts', code: 'arts', visible: true, allow: ['all'] },
    Memes: { name: 'Memes', code: 'memes', visible: true, allow: ['all'] },
    NFTGame: {
        name: 'NFT Game',
        code: 'nft-game',
        visible: false,
        allow: [
            '0xEfb90629166B74A397b9a02a4A4bebB2b10fBF45',
            '0x2Ba3B6b6a62F7B0f5bdca8026e09eFF87f5000fF',
        ],
    },
    RealItem: {
        name: 'Real Item',
        code: 'real-item',
        visible: false,
        allow: [
            '0xEfb90629166B74A397b9a02a4A4bebB2b10fBF45',
            '0x2Ba3B6b6a62F7B0f5bdca8026e09eFF87f5000fF',
        ],
    },
}

export const CATEGORIES = [
    NFTCategory.Arts,
    NFTCategory.Memes,
    NFTCategory.NFTGame,
    NFTCategory.RealItem,
]
export const LEGENDS = [
    { name: 'immortal' },
    { name: 'seasonal' },
    { name: 'legendary' },
    { name: 'ancient' },
    { name: 'common' },
    { name: 'uncommon' },
]

export const RARITY = [
    { name: 'Ordinary' },
    { name: 'Uncommon' },
    { name: 'Rare' },
    { name: 'Ultra Rare' },
]

export const BNB = 'BNB'
export const HPS = 'HPS'
export const BHC = 'BHC'
export const BUSD = 'BUSD'
export const WBNB = 'WBNB'

export const CRYPTO_OPTIONS = [{ name: BNB }, { name: HPS }]

export const MINTER_ADDRESS = '0x2a7975f14abb4d63a7a4c7c7f5a9ed8230b3cbe1'
// export const EXCHANGE_ADDRESS = '0x9A8371cA275dB476A4E10ACAd3B5E6dcf5028dA3'
export const EXCHANGE_ADDRESS = '0x12335D3E4063A0C17B49C33697087751FB5FE0Da'
export const STAKE_ADDRESS = '0x7fEcEcBf89549948D5fcce6c8f548a1E11ec8108'

export const HPS_721_ADDRESS = '0x9AB56134F1e84FD13cf969dF4F2a8694381544a1'
export const HPS_1155_ADDRESS = '0x33c2C043529D1AAdd883076Ecef2f163a2A9007b'
export const NFT_STORAGE_ADDRESS = '0x4e63370c341949961e8132fdcc74f7725d622211'
export const HPS_ADDRESS = '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D'
export const BHC_ADDRESS = '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4'
export const BNB_ADDRESS = '0x0000000000000000000000000000000000000000'
export const BUSD_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
export const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

export const TRANSFER_PROXY_ADDRESS =
    '0x071bf1d01cacd927d8aef94d7c1d635052eda3e2'

export const ERC_20_TRANSFER_PROXY_ADDRESS =
    '0xcabab2ccc8e143e6f306902abcaf77e52271bd1e'
export const CONTRACT_FACTORY_ADDRESS =
    '0x8790a2b7bc235a401acde34f44a00cb355194123'

export const NFT_SOLO = 'solo'
export const NFT_BATCH = 'batch'

export const URL_VALIDATE_PATTERN =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/

export const PINATA_GATEWAY = process.env.REACT_APP_PINATA_GATEWAY

export const PRODUCTS_LIMIT = 24
export const PRODUCT_SORT_TYPES = {
    RECENTLY_ADDED: 'RECENTLY_ADDED',
    CHEAPEST: 'CHEAPEST',
    HIGHEST_PRICE: 'HIGHEST_PRICE',
}

export const MAX_COPIES = 1000000000000000
export const DEFAULT_PRODUCTS_CATEGORY = 'ALL'

export const toAddress = (addressString) => {
    return ethers.utils.isAddress(addressString) ? addressString : null
}

export const getTokenByCurrency = (currency) => {
    switch (currency) {
        case BNB:
            return BNB_ADDRESS
        case BHC:
            return BHC_ADDRESS
        case HPS:
            return HPS_ADDRESS
        case BUSD:
            return BUSD_ADDRESS
        case WBNB:
            return WBNB_ADDRESS
        default:
            return ''
    }
}
export const getCurrencyByToken = (currency) => {
    switch (currency) {
        case BNB_ADDRESS:
            return BNB
        case BHC_ADDRESS:
            return BHC
        case HPS_ADDRESS:
            return HPS
        case BUSD_ADDRESS:
            return BUSD
        default:
            return BNB
    }
}

export const actionMenuTypes = {
    BURN_MENU: 'BURN_MENU',
    TRANSFER_MENU: 'TRANSFER_MENU',
    CHANGE_PRICE_MENU: 'CHANGE_PRICE_MENU',
    PUT_ON_SALE: 'PUT_ON_SALE',
    REMOVE_FROM_MARKET: 'REMOVE_FROM_MARKET',
    REPORT_NFT: 'REPORT_NFT',
    BUY_NOW_NFT: 'BUY_NOW_NFT',
    OPEN_FOR_BID: 'OPEN_FOR_BID',
    CLAIM_ITEM: 'CLAIM_ITEM',
}

export const buyNowAppearance = {
    INSIDE_DETAILED: 'INSIDE_DETAILED',
    INSIDE_CARD: 'INSIDE_CARD',
}

export const DEFAULT_TOP_ARTIST_TYPE = 'SELL'
export const DEFAULT_TOP_ARTIST_DURATION = 'CURRENT_MONTH'
