/* eslint-disable no-unused-vars */
import axios from 'axios'
import {
    DEFAULT_PRODUCTS_CATEGORY,
    DEFAULT_TOP_ARTIST_DURATION,
    DEFAULT_TOP_ARTIST_TYPE,
    PRODUCTS_LIMIT,
    PRODUCT_SORT_TYPES,
} from '../utility/constant'
const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT

export const createProfile = (formData) =>
    axios.post(`${ENDPOINT}/profile`, formData)

export const uploadFile = (formData) => {
    return axios.post(`${ENDPOINT}/pinata/upload`, formData, {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
    })
}

export const getProfileDataBYAddress = (address) =>
    axios.get(`${ENDPOINT}/profile/${address}`)

export const directUploadToIPFS = (formData) => {
    const pinata_api_key = process.env.REACT_APP_PINATA_API_KEY
    const pinata_secret_api_key = process.env.REACT_APP_PINATA_SECRET_API_KEY

    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`
    return axios.post(url, formData, {
        maxBodyLength: 'Infinity',
        headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            pinata_api_key: pinata_api_key,
            pinata_secret_api_key: pinata_secret_api_key,
        },
    })
}

export const createIPFSCollectable = (data) => {
    return axios.post(`${ENDPOINT}/pinata/collectable`, data)
}

export const getIPFSRecords = (hash) => {
    return axios.get(`https://gateway.pinata.cloud/ipfs/${hash}`)
}

//#region PRODUCTS SERVICES
export const queryAllProducts = (
    page,
    sortBy = PRODUCT_SORT_TYPES.RECENTLY_ADDED,
    category = DEFAULT_PRODUCTS_CATEGORY,
    limit = PRODUCTS_LIMIT
) => {
    return axios.get(
        `${ENDPOINT}/stock?page=${page}&limit=${limit}&sortBy=${sortBy}&category=${category}`
    )
}

export const updateProductDocumentWithIPFS = (productId) =>
    axios.post(`${ENDPOINT}/product/ipfs-update/${productId}`)

export const getProductDetails = (collection, current_owner, token_id) => {
    return axios.get(
        `${ENDPOINT}/stock/${collection}/${current_owner}/${token_id}`
    )
}

export const getNFTCollectible = (collection, token_id) => {
    return axios.get(`${ENDPOINT}/get-nft/${collection}/${token_id}`)
}

//#endregion PRODUCTS SERVICES

export const getNFTOnSale = (address) => {
    return axios.get(`${ENDPOINT}/user-product-onsale/${address}`)
}

export const getNFTCreated = (address) => {
    return axios.get(`${ENDPOINT}/user-product-created/${address}`)
}

export const getNFTCollectibles = (address) => {
    return axios.get(`${ENDPOINT}/user-product-collectibles/${address}`)
}

export const getNFTLiked = (address) => {
    return axios.get(`${ENDPOINT}/user-product-liked/${address}`)
}
export const getFollowers = (address) => {
    return axios.get(`${ENDPOINT}/profile/followers/${address}`)
}
export const getFollowings = (address) => {
    return axios.get(`${ENDPOINT}/profile/followings/${address}`)
}

export const getProfiles = (
    type = DEFAULT_TOP_ARTIST_TYPE,
    duration = DEFAULT_TOP_ARTIST_DURATION
) => {
    return axios.get(`${ENDPOINT}/profile?type=${type}&duration=${duration}`)
}

export const getProfilesByAddresses = (addreses = '') => {
    return axios.get(`${ENDPOINT}/profile-by-address?ids=${addreses}`)
}

export const postPutOnsale = (data) => {
    return axios.post(`${ENDPOINT}/stock-put-on-sale`, data)
}

export const updateOnsale = (data) => {
    return axios.put(`${ENDPOINT}/stock-put-on-sale`, data)
}

export const deleteOnsale = (data) => {
    // console.log('data', data)

    return axios.delete(`${ENDPOINT}/stock-put-on-sale`, { data })
}

export const postAddOrRemoveFromWishList = (data) =>
    axios.post(`${ENDPOINT}/product/wishlist`, data)

export const postBiddingStatus = (data) =>
    axios.post(`${ENDPOINT}/bidding-token`, data)

export const postPlaceBid = (data) => axios.post(`${ENDPOINT}/place-bid`, data)

export const deleteBidData = (data) =>
    axios.post(`${ENDPOINT}/delete-bid`, data)

export const searchByTerm = (searchTerm) =>
    axios.get(`${ENDPOINT}/search?term=${searchTerm}`)

export const createOrUpdateFollower = (payload) =>
    axios.post(`${ENDPOINT}/profile/followers`, payload)

export const waitForTnx = (payload) => {
    return axios.post(`${ENDPOINT}/wait-for-tnx`, payload)
}

export const getProductFromTransfers = (collection, token) => {
    return axios.get(
        `${ENDPOINT}/product-collectibles-transfers/${collection}/${token}`
    )
}

export const removeBid = (id) => axios.delete(`${ENDPOINT}/bid/${id}`)

export const reportNFT = (payload) => axios.post(`${ENDPOINT}/report`, payload)
