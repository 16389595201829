import {
    DEFAULT_PRODUCTS_CATEGORY,
    PRODUCT_SORT_TYPES,
} from '../../utility/constant'
import {
    FETCH_PRODUCTS,
    RESET_PRODUCTS,
    SET_CATEGORY,
    SET_LOADED_PAGE,
    SET_SORT_BY,
    UPDATE_PRODUCTS_IPFS,
    NFT_LIKED,
    NFT_ONSALE,
    NFT_COLLECTIBLES,
    NFT_CREATED,
    FOLLOWING,
    FOLLOWERS,
} from '../action-types/productsActionTypes'

const initialState = {
    loadedPage: 0,
    sortBy: PRODUCT_SORT_TYPES.RECENTLY_ADDED,
    category: DEFAULT_PRODUCTS_CATEGORY,
    data: [],
    myNft: {
        onSale: [],
        collectibles: [],
        created: [],
        liked: [],
        followings: [],
        followers: [],
    },
    nftLiked: null,
    nftOnSale: null,
    nftCollectibles: null,
    nftCreated: null,
    followings: null,
    followers: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_LOADED_PAGE:
            return { ...state, loadedPage: payload }
        case SET_SORT_BY:
            return { ...state, sortBy: payload, loadedPage: 1 }
        case SET_CATEGORY:
            return { ...state, category: payload, loadedPage: 1 }
        case FETCH_PRODUCTS:
            return {
                ...state,
                data:
                    payload.page === 1
                        ? [...payload.list]
                        : [...state.data, ...payload.list],
            }
        case RESET_PRODUCTS:
            return { ...state, data: [] }
        case UPDATE_PRODUCTS_IPFS:
            return {
                ...state,
                data: state.data.map((product) =>
                    product._id === payload._id ? payload : product
                ),
            }
        case NFT_LIKED:
            return {
                ...state,
                nftLiked: payload,
            }
        case NFT_ONSALE:
            return {
                ...state,
                nftOnSale: payload,
            }
        case NFT_COLLECTIBLES:
            return {
                ...state,
                nftCollectibles: payload,
            }
        case NFT_CREATED:
            return {
                ...state,
                nftCreated: payload,
            }
        case FOLLOWING:
            return {
                ...state,
                followings: payload,
            }
        case FOLLOWERS:
            return {
                ...state,
                followers: payload,
            }

        default:
            return state
    }
}
