import loadable from '../app/components/lazy/loadable'
import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const style = makeStyles((theme) => ({
    backdrop: {
        zIndex: 2000,
        color: theme.palette.common.white,
    },
}))

const fallback = (
    <Backdrop className={style.backdrop} open={true}>
        <CircularProgress />
    </Backdrop>
)

export const AboutUs = loadable(() => import('../app/pages/AboutUs'), {
    fallback,
})

export const Bhc = loadable(() => import('../app/pages/Bhc'), {
    fallback,
})

export const CreateNFT = loadable(() => import('../app/pages/CreateNFT'), {
    fallback,
})

export const CreateNFTForm = loadable(
    () => import('../app/pages/CreateNFTForm'),
    {
        fallback,
    }
)

export const ExternalProfile = loadable(
    () => import('../app/pages/ExternalProfile'),
    {
        fallback,
    }
)

export const Faq = loadable(() => import('../app/pages/Faq'), {
    fallback,
})

export const Home = loadable(() => import('../app/pages/Home'), {
    fallback,
})

export const MarketPlace = loadable(() => import('../app/pages/MarketPlace'), {
    fallback,
})

export const NFTProductDetails = loadable(
    () => import('../app/pages/NFTProductDetails'),
    {
        fallback,
    }
)

export const Profile = loadable(() => import('../app/pages/Profile'), {
    fallback,
})

export const Stake = loadable(() => import('../app/pages/Stake'), {
    fallback,
})

export const Stakes = loadable(() => import('../app/pages/Stakes'), {
    fallback,
})
