/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux'
import { NFT_LIKED } from '../redux/action-types/productsActionTypes'
import { getNFTLiked, postAddOrRemoveFromWishList } from '../services'
import useBlockchain from './useBlockchain'

const useWishlist = () => {
    const dispatch = useDispatch()
    const { sign } = useBlockchain()
    const {
        wallet: { address },
        products: { nftLiked },
    } = useSelector((state) => state)

    const fetchWishlist = async (_address) => {
        const results = await getNFTLiked(_address)
        if (results && results.data) {
            dispatch({ type: NFT_LIKED, payload: results.data })
        }
    }

    const isWishlistItem = (collection, token_id) => {
        const likedItem =
            nftLiked &&
            nftLiked.find(
                (product) =>
                    product.collection === collection &&
                    product.token_id === token_id
            )
        if (likedItem) {
            return true
        } else {
            return false
        }
    }
    const addOrUpdateWishlist = (
        { liked, product },
        callbackFunction = null
    ) => {
        const { collection, token_id, owner } = product

        sign('I agree to like or dislike this product.').then(
            async (signRes) => {
                const payload = {
                    contract: collection,
                    token_id,
                    liked: liked ? 1 : 0,
                    address,
                    sign: signRes,
                    owner,
                }

                var newLikes = nftLiked
                if (liked) {
                    newLikes.push(product)
                } else {
                    newLikes = nftLiked.filter(({ id }) => id !== product.id)
                }
                dispatch({ type: NFT_LIKED, payload: newLikes })
                postAddOrRemoveFromWishList(payload).then((res) => {
                    if (res.data) {
                        fetchWishlist(address)
                        callbackFunction && callbackFunction()
                    }
                })
            }
        )
    }

    return { isWishlistItem, addOrUpdateWishlist, fetchWishlist }
}

export default useWishlist
