import React, { useEffect } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getMuiTheme } from './themeConfigs'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getProfileDataBYAddress } from '../services'
import { setAuthProfile } from '../redux/actions/authActions'
import useWishlist from '../hooks/useWishlist'

function AppThemeProvider({ children }) {
    const dispatch = useDispatch()
    const { theme_mode } = useSelector((state) => state.master)
    const { address } = useSelector((state) => state.wallet)
    const theme = getMuiTheme(theme_mode)

    useWishlist()
    useEffect(() => {
        if (address) {
            getProfileDataBYAddress(address)
                .then((profileRes) => {
                    if (profileRes?.data) {
                        dispatch(setAuthProfile(profileRes?.data))
                    }
                })
                .catch(() => {
                    dispatch(setAuthProfile(null))
                })
        }
    }, [address])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}

AppThemeProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
}

export default AppThemeProvider
