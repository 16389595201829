//wallet connect
export const CONNECT_WALLET_BEGIN = 'HOME_CONNECT_WALLET_BEGIN'
export const CONNECT_WALLET_SUCCESS = 'HOME_CONNECT_WALLET_SUCCESS'
export const CONNECT_WALLET_FAILURE = 'HOME_CONNECT_WALLET_FAILURE'
export const ACCOUNTS_CHANGED = 'HOME_ACCOUNTS_CHANGED'
export const NETWORK_CHANGED = 'HOME_NETWORK_CHANGED'

export const DISCONNECT_WALLET_BEGIN = 'HOME_DISCONNECT_WALLET_BEGIN'
export const DISCONNECT_WALLET_SUCCESS = 'HOME_DISCONNECT_WALLET_SUCCESS'
export const DISCONNECT_WALLET_FAILURE = 'HOME_DISCONNECT_WALLET_FAILURE'

export const IS_CONNECT = 'IS_CONNECT'
export const IS_DISCONNECT = 'IS_DISCONNECT'
