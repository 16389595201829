import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EXCHANGE_ABI from '../utility/exchange_abi.json'
import BHC_721 from '../utility/bhc_721.json'
import { EXCHANGE_ADDRESS, NFT_STORAGE_ADDRESS } from '../utility/constant'
import NFT_STORAGE_ABI from '../utility/nft_storage.json'
import BHC1155ABI from '../utility/bhc_1155.json'

const useBlockchain = () => {
    const { web3, address: walletAddress } = useSelector(
        (state) => state.wallet
    )

    var provider = web3

    const [address, setAddress] = useState(null)

    useEffect(() => {
        if (provider) {
            setAddress(() =>
                provider.utils.isAddress(walletAddress) ? walletAddress : null
            )
        }
    }, [walletAddress, provider])

    const checkNFTType = async (collectionAddress) => {
        if (provider) {
            const contract = new provider.eth.Contract(
                BHC_721,
                collectionAddress
            )

            const is721 = await contract.methods
                .supportsInterface('0x80ac58cd')
                .call()
            if (is721) {
                return 'solo'
            }

            if (!is721) {
                const contract = new provider.eth.Contract(
                    BHC1155ABI,
                    collectionAddress
                )

                const is1155 = await contract.methods
                    .supportsInterface('0x0e89341c')
                    .call()

                if (is1155) {
                    return 'batch'
                }
            }
        }
    }

    const sign = (message) => {
        return provider.eth.personal.sign(message, address)
    }

    const generateOrderId = async (
        tokenAddress,
        tokenId,
        value,
        priceToken,
        price,
        salt
    ) => {
        if (provider) {
            const contract = new provider.eth.Contract(
                EXCHANGE_ABI,
                EXCHANGE_ADDRESS
            )

            const order = await contract.methods
                .generateKey(
                    tokenAddress,
                    tokenId,
                    value,
                    priceToken,
                    provider.utils.toWei(`${price}`, 'ether'),
                    salt
                )
                .call()

            const nftStorage = new provider.eth.Contract(
                NFT_STORAGE_ABI,
                NFT_STORAGE_ADDRESS
            )

            const orderData = await nftStorage.methods.getOrder(order).call()

            return orderData
        }
    }

    const generateMessage = async (
        tokenAddress,
        tokenId,
        value,
        priceToken,
        price,
        salt
    ) => {
        if (provider) {
            const contract = new provider.eth.Contract(
                EXCHANGE_ABI,
                EXCHANGE_ADDRESS
            )

            const message = await contract.methods
                .generateMessage(
                    tokenAddress,
                    tokenId,
                    value,
                    priceToken,
                    provider.utils.toWei(`${price}`, 'ether'),
                    salt
                )
                .call()
            return message
        }
    }

    return {
        address,
        sign,
        checkNFTType,
        generateOrderId,
        generateMessage,
    }
}

export default useBlockchain
