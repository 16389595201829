import {
    CONNECT_WALLET_BEGIN,
    CONNECT_WALLET_SUCCESS,
    NETWORK_CHANGED,
    ACCOUNTS_CHANGED,
    CONNECT_WALLET_FAILURE,
    DISCONNECT_WALLET_FAILURE,
    DISCONNECT_WALLET_SUCCESS,
    DISCONNECT_WALLET_BEGIN,
    IS_CONNECT,
    IS_DISCONNECT,
} from '../action-types/walletActionTypes'

const initialState = {
    web3: null,
    address: null,
    networkId: 0,
    connected: false,
    isWalletConnecting: false,
    isConnected: null,
}
export default function (state = initialState, action) {
    const { type } = action

    switch (type) {
        case CONNECT_WALLET_BEGIN:
            return {
                ...state,
                isWalletConnecting: true,
            }
        case CONNECT_WALLET_SUCCESS:
            return {
                ...state,
                isWalletConnecting: false,
                address: action.items.address,
                connected: true,
                isConnected: true,
                web3: action.items.web3,
                networkId: action.items.networkId,
            }
        case NETWORK_CHANGED:
            return {
                ...state,
                networkId: action.data,
            }

        case ACCOUNTS_CHANGED:
            return {
                ...state,
                address: action.data.account,
                connected: true,
                isConnected: true,
                web3: action.data.web3,
            }
        case CONNECT_WALLET_FAILURE:
            return {
                ...state,
                isWalletConnecting: false,
            }
        case DISCONNECT_WALLET_BEGIN:
            return {
                ...state,
                isWalletDisconnecting: true,
            }

        case DISCONNECT_WALLET_SUCCESS:
            return {
                ...state,
                isWalletDisconnecting: false,
                address: '',
                connected: false,
                isConnected: false,
                web3: null,
                networkId: 0,
            }
        case DISCONNECT_WALLET_FAILURE:
            return {
                ...state,
                isWalletDisconnecting: false,
                address: '',
                connected: false,
                isConnected: false,
                web3: null,
                networkId: 0,
            }
        case IS_CONNECT:
            return {
                ...state,
                isConnected: true,
            }
        case IS_DISCONNECT:
            return {
                ...state,
                isConnected: false,
            }

        default:
            return state
    }
}
