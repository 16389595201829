import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
// import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import reduxThunk from 'redux-thunk'
import auth from '../reducers/authReducer'
import master from '../reducers/masterReducer'
import products from '../reducers/productsReducer'
import wallet from '../reducers/walletReducer'

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose

const enhancer = composeEnhancers(applyMiddleware(reduxThunk))

const persistConfig = {
    key: 'nft_root',
    storage,
    whitelist: ['master'],
    // whitelist: ['master', 'wallet'],
}
const rootReducer = persistReducer(
    persistConfig,
    combineReducers({ master, wallet, auth, products })
)

export const store = createStore(rootReducer, enhancer)
export const persister = persistStore(store)
