import { SET_AUTH_PROFILE_DATA } from '../action-types/authActionTypes'

const initialState = {
    profile: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_AUTH_PROFILE_DATA:
            return { ...state, profile: payload }

        default:
            return state
    }
}
