import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route, HashRouter, Switch } from 'react-router-dom'
import Web3 from 'web3'

import {
    IS_CONNECT,
    IS_DISCONNECT,
} from './redux/action-types/walletActionTypes'
import {
    AboutUs,
    NFTProductDetails,
    MarketPlace,
    ExternalProfile,
    Profile,
    CreateNFTForm,
    CreateNFT,
    Bhc,
    Faq,
    Home,
    Stake,
    Stakes,
} from './pageListAsync'

export default function AppRoutes() {
    const {
        wallet: { isConnected },
    } = useSelector((state) => state)

    const dispatch = useDispatch()

    useEffect(() => {
        const checkConnection = async () => {
            let web3
            if (window.ethereum) {
                web3 = new Web3(window.ethereum)
            } else if (window.web3) {
                web3 = new Web3(window.web3.currentProvider)
            }

            web3 &&
                web3.eth.getAccounts().then(async (addr) => {
                    if (addr.length > 0) {
                        dispatch({ type: IS_CONNECT })
                    } else {
                        dispatch({ type: IS_DISCONNECT })
                    }
                })
        }
        checkConnection()
    }, [])

    return (
        <HashRouter>
            <Switch>
                <Route path="/about">
                    <AboutUs />
                </Route>
                <Route path="/marketplace/:collection/:current_owner/:token_id/:nftCreated?">
                    <NFTProductDetails />
                </Route>
                <Route path="/marketplace">
                    <MarketPlace />
                </Route>
                <Route path="/profile/:profileId">
                    <ExternalProfile />
                </Route>
                <Route path="/profile">
                    {isConnected === true && <Profile />}
                    {isConnected === false && <Redirect to="/" />}
                </Route>

                <Route path="/create-nft/:type">
                    {isConnected === true && <CreateNFTForm />}
                    {isConnected === false && <Redirect to="/" />}
                </Route>
                <Route path="/create-nft">
                    {isConnected === true && <CreateNFT />}
                    {isConnected === false && <Redirect to="/" />}
                </Route>
                <Route path="/hps">
                    <Bhc />
                </Route>
                <Route path="/faq">
                    <Faq />
                </Route>

                <Route path="/stake/:id">
                    <Stake />
                </Route>
                <Route path="/stake">
                    <Stakes />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </HashRouter>
    )
}
