import { TOGGLE_THEME_MODE, PROFILES } from '../action-types/masterActionTypes'

const initialState = {
    theme_mode: 'light',
    profiles: [],
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case TOGGLE_THEME_MODE:
            return { ...state, theme_mode: payload }
        case PROFILES:
            return { ...state, profiles: payload }

        default:
            return state
    }
}
